// import React, { useEffect } from 'react';
// import './Games.css';
// import { Link, useLocation } from 'react-router-dom';

// const Games = () => {

//   const location = useLocation(); // Get current route information

//   // Scroll to top on route change
//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, [location]); // Re-run on location change


//   return (
//     <div>

//       <div className='Banner'>
//           <img src="/images/gamesavatars.png" alt="Avatars Logo" className="avatarslogo" />  
//           <img src="/images/gameroomlogo.png" alt="GameRoom Logo" className="gameroomlogo" /> 
//       </div>

//       <div className='ZombiesFromOuterSpace'>
//           <img src="/images/jatranimation.gif" alt="Animation Logo" className="animationlogo" />
//           <div>Play Jack and The Ripper's New 3D VIDEO GAME<br/>Online Desktop Version</div>
//           <div className='buttoncontainer'>
//             <div class="button-wrapper">  <div>Jack</div>
//               <a href="https://simmer.io/@JATR/jatr-3d-zombies-from-outer-space"><img src="/images/bluebuttonjack.png" alt="Jack Button" className="jackbutton" /></a>
//             </div>
//             <div class="button-wrapper">  <div>Ripper</div>
//             <a href="https://simmer.io/@JATR/jatr-r-3d-zombies-from-outer-space"><img src="/images/redbuttonripper.png" alt="Ripper Button" className="ripperbutton" /></a>
//             </div>
//           </div>
//           <div>
//         </div>

//       <div className='HoverSk8'>
//           <img src="/images/hoversk8logo.png" alt="Sk8 Logo" className="sk8logo" />
//           <img src="/images/hoversk8ripperscreen.png" alt="Sk8 Screen" className="sk8screen" />
//           <div>ONLINE DESKTOP VERSION</div>
//           <a href="https://simmer.io/@JATR/jatr-sci-fi-hoversk8"><img src="/images/greenbuttonhoversk8.png" alt="Green Button" className="greenbutton" /></a>
//       </div>
      
//       <div className='threeDBowlingGame'>
//         <img src="/images/bowlinglogo.png" alt="Bowling Logo" className="bowlinglogo" />
//         <img src="/images/jackbowlingscreen.png" alt="Bowling Screen" className="bowlingscreen" />
//         <div>ONLINE DESKTOP VERSION</div>
//         <a href="https://simmer.io/@JATR/jackandtheripper-bowling3dgame"><img src="/images/bluebuttonbowling.png" alt="Blue Button" className="bluebutton" /></a>
//       </div>


//       <div className='twoDGame'>
//         <img src="/images/jatr2dlogo.png" alt="2d Logo" className="twodlogo" />
//         <img src="/images/2dgamescreen.png" alt="2d Screen" className="twodgamescreen" />
//         <div>ONLINE DESKTOP / MOBILE VERSION</div>
//         {/* <a href="https://www.jackandtheripper.tv/jatr2d.html"><img src="/images/redbutton2dgame.png" alt="Red Button" className="redbutton" /></a> */}
//         <Link to="/jatr2d"><img src="/images/redbutton2dgame.png" alt="Red Button" className="redbutton" /></Link>
//       </div>

//     </div>
//   </div>
//   )
// }

// export default Games









import React, { useEffect } from 'react';
import './Games.css';
import { Link, useLocation } from 'react-router-dom';

const Games = () => {

  const location = useLocation(); // Get current route information

  // Scroll to top on route change
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]); // Re-run on location change


  return (
    <div>

      <div className='Banner'>
          <img src="/images/gamesavatars.png" alt="Avatars Logo" className="avatarslogo" />  
          <img src="/images/gameroomlogo.png" alt="GameRoom Logo" className="gameroomlogo" /> 
      </div>

      <div className='ZombiesFromOuterSpace'>
          <img src="/images/jatranimation.gif" alt="Animation Logo" className="animationlogo" />
          <div>Play Jack and The Ripper's New 3D VIDEO GAME<br/>Online Desktop Version</div>
          <div className='buttoncontainer'>
            <div class="button-wrapper">  <div>Jack</div>
              <a href="https://simmer.io/@JATR/jatr-3d-zombies-from-outer-space"><img src="/images/bluebuttonpressplaybig.png" alt="Jack Button" className="jackbutton" /></a>
            </div>
            <div class="button-wrapper">  <div>Ripper</div>
            <a href="https://simmer.io/@JATR/jatr-r-3d-zombies-from-outer-space"><img src="/images/redbuttonpressplaybig.png" alt="Ripper Button" className="ripperbutton" /></a>
            </div>
          </div>
          <div>
        </div>

      <div className='HoverSk8'>
          <img src="/images/hoversk8logo.png" alt="Sk8 Logo" className="sk8logo" />
          <img src="/images/hoversk8ripperscreen.png" alt="Sk8 Screen" className="sk8screen" />
          <div>ONLINE DESKTOP VERSION</div>
          <a href="https://simmer.io/@JATR/jatr-sci-fi-hoversk8"><img src="/images/greenbuttonpressplaybig.png" alt="Green Button" className="greenbutton" /></a>
      </div>
      
      <div className='threeDBowlingGame'>
        <img src="/images/bowlinglogo.png" alt="Bowling Logo" className="bowlinglogo" />
        <img src="/images/jackbowlingscreen.png" alt="Bowling Screen" className="bowlingscreen" />
        <div>ONLINE DESKTOP VERSION</div>
        <a href="https://simmer.io/@JATR/jackandtheripper-bowling3dgame"><img src="/images/bluebuttonpressplaybig.png" alt="Blue Button" className="bluebutton" /></a>
      </div>


      <div className='twoDGame'>
        <img src="/images/jatr2dlogo.png" alt="2d Logo" className="twodlogo" />
        <img src="/images/2dgamescreen.png" alt="2d Screen" className="twodgamescreen" />
        <div>ONLINE DESKTOP / MOBILE VERSION</div>
        {/* <a href="https://www.jackandtheripper.tv/jatr2d.html"><img src="/images/redbutton2dgame.png" alt="Red Button" className="redbutton" /></a> */}
        <Link to="/jatr2d"><img src="/images/redbuttonpressplaybig.png" alt="Red Button" className="redbutton" /></Link>
      </div>

    </div>
  </div>
  )
}

export default Games