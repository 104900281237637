import React from 'react';
import './VRAR.css'; // Import the CSS file

const videoData = [
  { url: 'https://www.youtube.com/embed/fV3uZHCymGc?si=92EzV9UBco2hOe5L', title: 'Video 1' },
  { url: 'https://www.youtube.com/embed/DsEJhFzU_Gg?si=Uk8XjgdmdJJTTSyw', title: 'Video 2' },
  { url: 'https://www.youtube.com/embed/henPYKuOulM?si=uRwyZ8CY-7ZGe1VB', title: 'Video 3' },
  { url: 'https://www.youtube.com/embed/ARV449ZLX44?si=zFwjpL_ZrqkOHfOv', title: 'Video 4' },
  { url: 'https://www.youtube.com/embed/3VCMnJdnmbw?si=2mY1LRPlKR9n13Pi', title: 'Video 5' },
  { url: 'https://www.youtube.com/embed/BabpJF0sjJ4?si=If9FUELW7Wb9qbqR', title: 'Video 6' },
  { url: 'https://www.youtube.com/embed/Jw9UWpNMWtg?si=koVF4tOyOIWV-8PH', title: 'Video 7' },
  { url: 'https://www.youtube.com/embed/8PYpFn-vMXk?si=kD3NP9DvPsm_su_7', title: 'Video 8' },
  { url: 'https://www.youtube.com/embed/kPc0IUcszKE?si=tzZprh8zy-vJ8B9I', title: 'Video 9' },
];

const VRAR = () => {
  return (
    <div className="videos-container">
      <img src="/images/VRAR.png" alt="VRAR Logo" className="logoVR" />
      {videoData.map((video) => (
        <div key={video.url} className="video-item">
          <iframe
            width="560"
            height="315"
            src={video.url}
            title={video.title}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
          {/* <p>{video.title}</p> */}
        </div>
      ))}
    </div>
  );
};

export default VRAR;