// Home.js

import React from 'react';
import './Home.css';
import ReactPlayer from 'react-player';

function Home() {
  const youtubeUrl = 'https://www.youtube.com/embed/-zvKpjHNCLI';

  return (
    <section id="home">
      <div className="video-container">
        <ReactPlayer url={youtubeUrl} width="560px" height="315px" controls />
      </div>
      <h2>New Single "Exile" Out Now!</h2>
      <p>Music Video Generated with AI</p>
    </section>
  );
}

export default Home;
