//Blog.js

import React from 'react';
import './Blog.css';

const Blog = () => {
  const tumblrUrl = 'https://jackandtheripper.tumblr.com/'; 
  
  return (
    <div className="blog-container">
      <iframe
        src={tumblrUrl}
        title="Jack and the Ripper Tumblr Feed"
        width="100%"
        height="400px"
        frameBorder="0"
        style={{ overflowY: 'scroll', 
                scrollbarWidth: 'thin', 
                scrollbarColor: '#ccc transparent' 
              }}
      />
    </div>
  );
};

export default Blog;








// import React from 'react';

// const Blog = () => {
//   const tumblrUrl = 'https://jackandtheripper.tumblr.com/'; // Replace with your actual Tumblr URL
//   return (
//     <div className="blog-container">
//       {/* <h2>Blog Posts (Limited View)</h2> */}
//       <iframe
//         src={tumblrUrl}
//         title="Jack and the Ripper Tumblr Feed"
//         width="100%"
//         height="400px"
//         frameBorder="0"
//         style={{ overflowY: 'scroll', /* Enable scrollbar */
//                 scrollbarWidth: 'thin', /* Optional custom scrollbar width */
//                 scrollbarColor: '#ccc transparent' /* Optional custom scrollbar color */
//               }}
//       />
//       {/* <p>
//         **Important:** This iframe displays a limited portion of the blog. To view full posts and scroll through them, please visit{' '}
//         <a href={tumblrUrl} target="_blank" rel="noreferrer noopener">
//           Jack and the Ripper Tumblr
//         </a>
//       </p> */}
//     </div>
//   );
// };

// export default Blog;