//App.js

import React from 'react';
import './App.css'; 
import Footer from './components/Footer';
import Contact from './components/Contact'; 
import Home from './components/Home'; 
import News from './components/News';
import Music from './components/Music';
import Videos from './components/Videos';
import Games from './components/Games';
import VRAR from './components/VRAR';
import Photos from './components/Photos';
import Store from './components/Store';
import Blog from './components/Blog';
import Bio from './components/Bio';
import Jatr2d from './components/Jatr2d';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom'; 

function App() {

  return (
    <BrowserRouter>
      <div className="App">
        <header>
          <img src="/images/jatrlogo.png" alt="Band Logo" className="logo" />
          <nav>
            <ul>
              <li><Link to="/">Home</Link></li>
              <li><Link to="/bio">Bio</Link></li>
              <li><Link to="/contact">Contact</Link></li> 
              <li><Link to="/news">News</Link></li> 
              <li><Link to="/music">Music</Link></li> 
              <li><Link to="/videos">Videos</Link></li> 
              <li><Link to="/games">Games</Link></li> 
              <li><Link to="/vrar">VR/AR</Link></li> 
              <li><Link to="/photos">Photos</Link></li> 
              <li><Link to="/store">Store</Link></li> 
              <li><Link to="/blog">Blog</Link></li> 
            </ul>
          </nav>
        </header>
        <main className="content">
          <Routes>
            <Route path="/" element={<Home />} /> 
            <Route path="/bio" element={<Bio />} /> 
            <Route path="/contact" element={<Contact />} />
            <Route path="/news" element={<News />} />
            <Route path="/music" element={<Music />} />
            <Route path="/videos" element={<Videos />} />
            <Route path="/games" element={<Games />} />
            <Route path="/vrar" element={<VRAR />} />
            <Route path="/photos" element={<Photos />} />
            <Route path="/store" element={<Store />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/jatr2d" element={<Jatr2d />} />
            <Route path="*" element={<div>404: Not Found</div>} /> 
          </Routes>
        </main>

        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;







// import React, { useState } from 'react';
// import './App.css'; 
// import Footer from './components/Footer';
// import Contact from './components/Contact'; 
// import Home from './components/Home'; 
// import News from './components/News';
// import Music from './components/Music';
// import Videos from './components/Videos';
// import Games from './components/Games';
// import VRAR from './components/VRAR';
// import Photos from './components/Photos';
// import Store from './components/Store';
// import Blog from './components/Blog';
// import { BrowserRouter, Routes, Route, Link } from 'react-router-dom'; 

// import BioEng from './components/BioEng';
// import BioEsp from './components/BioEsp';
// import Press from './components/Press';


// function App() {

//   const [isHomeSubmenuOpen, setIsHomeSubmenuOpen] = useState(false); // State for sub-menu visibility

//   const handleMouseOver = () => {
//     setIsHomeSubmenuOpen(true); // Set state to open on hover
//   };

//   const handleMouseLeave = () => {
//     setIsHomeSubmenuOpen(false); // Set state to close on mouse leave
//   };

//   return (
//     <BrowserRouter>
//       <div className="App">
//         <header>
//           <img src="/images/jatrlogo.png" alt="Band Logo" className="logo" />
//           <nav>
//             <ul>
//               {/* <li><Link to="/">Home</Link></li> */}
//               <li onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave}>
//                 <Link to="/">Home</Link>
//                 {isHomeSubmenuOpen && ( // Conditionally render sub-menu on hover
//                   <ul className="submenu">
//                     <li><Link to="/bio-eng">Bio Eng</Link></li>
//                     <li><Link to="/bio-esp">Bio Esp</Link></li>
//                     <li><Link to="/press">Press</Link></li>
//                   </ul>
//                 )}
//               </li>
//               <li><Link to="/contact">Contact</Link></li> 
//               <li><Link to="/news">News</Link></li> 
//               <li><Link to="/music">Music</Link></li> 
//               <li><Link to="/videos">Videos</Link></li> 
//               <li><Link to="/games">Games</Link></li> 
//               <li><Link to="/vrar">VR/AR</Link></li> 
//               <li><Link to="/photos">Photos</Link></li> 
//               <li><Link to="/store">Store</Link></li> 
//               <li><Link to="/blog">Blog</Link></li> 
//             </ul>
//           </nav>
//         </header>
//         <main className="content">
//           <Routes>
//             <Route path="/" element={<Home />} /> 
//             <Route path="/contact" element={<Contact />} />
//             <Route path="/news" element={<News />} />
//             <Route path="/music" element={<Music />} />
//             <Route path="/videos" element={<Videos />} />
//             <Route path="/games" element={<Games />} />
//             <Route path="/vrar" element={<VRAR />} />
//             <Route path="/photos" element={<Photos />} />
//             <Route path="/store" element={<Store />} />
//             <Route path="/blog" element={<Blog />} />
//             <Route path="/bio-eng" element={<BioEng />} />  {/* Route for BioEng component */}
//             <Route path="/bio-esp" element={<BioEsp />} />  {/* Route for BioEsp component */}
//             <Route path="/press" element={<Press />} />    {/* Route for Press component */}
//             <Route path="*" element={<div>404: Not Found</div>} /> 
//           </Routes>
//         </main>

//         <Footer />
//       </div>
//     </BrowserRouter>
//   );
// }

// export default App;






















// import React from 'react';
// import './App.css'; 
// import ReactPlayer from 'react-player';
// import Footer from './Footer';

// function App() {

//   const youtubeUrl = 'https://www.youtube.com/embed/-zvKpjHNCLI'; // Removed si parameter

//   return (
//     <div className="App">
//       <header>
//         <img src="/images/jatrlogo.png" alt="Band Logo" className="logo" />
//         <nav>
//           <ul>
//             <li><a href="#home">Home</a></li>
//             <li><a href="#contact">Contact</a></li>
//             <li><a href="#news">News</a></li>
//             <li><a href="#music">Music</a></li>
//             <li><a href="#videos">Videos</a></li>
//             <li><a href="#games">Games</a></li>
//             <li><a href="#vr-ar">VR/AR</a></li>
//             <li><a href="#photos">Photos</a></li>
//             <li><a href="#store">Store</a></li>
//             <li><a href="#blog">Blog</a></li>
//           </ul>
//         </nav>
//       </header>
//       <main className="content">
//           <section id="home">
//             <div className="video-container">
//               <ReactPlayer url={youtubeUrl} width="560px" height="315px" controls />
//             </div>
//             <h2>New Single "Exile" Out Now!</h2>
//             <p>Music Video Generated with AI</p>
//           </section>
//       </main>

//       <Footer />
//     </div>
//   );
// }

// export default App;




